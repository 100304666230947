import React, { useState, useReducer, useEffect } from "react";
import { ICONS } from "../constants/icons";
import {
  Appbar,
  Text,
  Menu,
  Dialog,
  Portal,
  Paragraph,
  Button,
  Modal,
} from "react-native-paper";
import { getHeaderTitle } from "@react-navigation/elements";
import {
  NavigationHelpers,
  ParamListBase,
  Route,
  useRoute,
} from "@react-navigation/native";
import { getFocusedRouteNameFromRoute } from "@react-navigation/native";
import { supabase } from "../initSupabase";
import { CalendarContext as RNCCalendarContext } from "react-native-calendars";
import { useCalendar } from "../provider/CalendarProvider";
import CoachViewSelectorChip from "../components/Coaches/CoachViewSelectorChip";
import { formatDateToCustomFormat } from "../components/utils/dateUtils";
import { hasAthletes, isCoach } from "../components/utils/coaches";
import { fetchMyAthleteProfiles } from "../api/coaches";
import ActivityAppBarMenu from "../components/Activity/ActivityAppBarMenu";
import LibraryWorkoutAppBarMenu from "../components/WorkoutLibrary/LibraryWorkoutAppBarMenu";
import EventAppBarMenu from "../components/Event/EventAppBarMenu";
import { SCREEN_NAMES } from "../constants/screenNames";

export type CustomAppBarProps = {
  navigation: NavigationHelpers<ParamListBase>;
  route: Route<string>;
  options: { title?: string; headerTitle?: any };
  back: boolean;
  handleActivityUpdate?: (updatedActivity: any) => void; // Add handleActivityUpdate prop
};

const CustomAppBar: React.FC<CustomAppBarProps> = ({
  navigation,
  route,
  options,
  back,
}) => {
  const checkRoute = useRoute();
  const { state, dispatch } = useCalendar();
  const tabName = getFocusedRouteNameFromRoute(route);
  const title =
    tabName !== undefined
      ? tabName === "Schedule"
        ? formatDateToCustomFormat(state.focusDate) // Use state.focusDate if tabName is "Schedule"
        : tabName // Use tabName for other tabs
      : getHeaderTitle(options, route.name);
  const [menuVisible, setMenuVisible] = useState(false);
  const [mergeVisible, setMergeVisible] = useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [coachWithAthletesView, setCoachWithAthletesView] = useState(false);

  const openMenu = () => setMenuVisible(true);
  const closeMenu = () => setMenuVisible(false);

  const containerStyle = { backgroundColor: "white", padding: 20 };

  const toggleCalendar = () => {
    dispatch({ type: "TOGGLE_CALENDAR" }); // Update the global state
  };

  const handleMore = () => {
    navigation.navigate("More"); // Navigating to the "More" screen
  };

  useEffect(() => {
    if (
      state.profile !== null &&
      isCoach(state.profile) &&
      state.athletes.length > 0
    ) {
      setCoachWithAthletesView(true);
    } else {
      setCoachWithAthletesView(false);
    }
  }, [state.athletes, state.profile]);

  const renderAppBarMenu = () => {
    switch (route.name) {
      case "Activity":
        return <ActivityAppBarMenu />;
      case "Library Workout":
        return <LibraryWorkoutAppBarMenu />;
      case SCREEN_NAMES.EVENT_DETAILS:
        return <EventAppBarMenu />;
      default:
        return null;
    }
  };

  return (
    <Appbar.Header>
      {back ? <Appbar.BackAction onPress={navigation.goBack} /> : null}
      <Appbar.Content title={title} />
      {coachWithAthletesView &&
        (tabName === "Home" ||
          tabName === "Schedule" ||
          tabName === "Insights") && (
          <CoachViewSelectorChip />
          // <Appbar.Action
          //   icon="account-filter"
          //   onPress={() => {
          //     /* Handle action press */
          //   }}
          // />
        )}
      {renderAppBarMenu()}
    </Appbar.Header>
  );
};

export default CustomAppBar;
