import * as React from "react";
import { View, StyleSheet } from "react-native";
import { List, Avatar, Badge, Text } from "react-native-paper";

const EventDetailsList = ({}) => (
  <List.AccordionGroup>
    <List.Accordion title="Goals" id="1">
      <List.Item title="Add a goal" />
    </List.Accordion>
    <List.Accordion title="Attachments" id="2">
      <List.Item title="Upload an attachment" />
    </List.Accordion>
    <List.Accordion title="Checklists" id="3">
      <List.Item title="Create a checklist" />
    </List.Accordion>
    <List.Accordion title="Analysis" id="4">
      <List.Item title="Charts" />
      <List.Item title="Time in Zones" />
      <List.Item title="Best Efforts" />
    </List.Accordion>
    <List.Accordion title="Splits" id="5">
      <List.Item title="Splits" />
    </List.Accordion>
    <List.Accordion title="Map" id="6">
      <List.Item title="Item 2" />
    </List.Accordion>
    <List.Accordion title="Notes" id="7">
      <List.Item title="Notes" />
    </List.Accordion>
  </List.AccordionGroup>
);

const styles = StyleSheet.create({
  badgeContainer: {
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
});

export default EventDetailsList;
