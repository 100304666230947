import React, { useState } from "react";
import { FAB } from "react-native-paper";
import { ICONS } from "../constants/icons";
import { SCREEN_NAMES } from "../constants/screenNames";

const ScheduleFabGroup = ({ navigation }) => {
  const [open, setOpen] = useState(false);

  return (
    <FAB.Group
      open={open}
      icon={open ? "close" : "plus"}
      actions={[
        {
          icon: ICONS.NOTE,
          label: "Note",
          onPress: () =>
            navigation.navigate(SCREEN_NAMES.EDIT_ACTIVITY, { activity: {} }),
        },
        {
          icon: ICONS.EVENT,
          label: "Event",
          onPress: () =>
            navigation.navigate(SCREEN_NAMES.EDIT_EVENT, { event: {} }),
        },
        {
          icon: ICONS.WORKOUT_LIBRARY,
          label: "Library Workout",
          onPress: () => navigation.navigate(SCREEN_NAMES.WORKOUT_LIBRARY),
        },
        {
          icon: ICONS.WORKOUT,
          label: "New Workout",
          onPress: () =>
            navigation.navigate(SCREEN_NAMES.EDIT_ACTIVITY, { activity: {} }),
        },
      ]}
      onStateChange={({ open }) => setOpen(open)}
      onPress={() => {
        if (open) {
          // FAB group is open
        } else {
          //   handleFabPress();
        }
      }}
    />
  );
};

export default ScheduleFabGroup;

{
  /* <Button mode="contained" onPress={() => navigation.navigate("SecondScreen")}>
  Second Screen
</Button>; */
}
