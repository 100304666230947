// src/constants/screenNames.ts
export const SCREEN_NAMES = {
  CHIRON: "Chiron",
  ABOUT: "About",
  SETTINGS: "Settings",
  ACCOUNT_SETTINGS: "Account Settings",
  EDIT_ACTIVITY: "Edit Activity",
  MERGE_WORKOUTS: "Merge Workouts",
  ACTIVITY_DETAILS: "Activity",
  RESET_PASSWORD: "ResetPassword",
  CONNECTED_APPS: "Connected Apps",
  CONNECT_STRAVA: "Connect with Strava",
  MY_COACHES: "My Coaches",
  MY_ATHLETES: "My Athletes",
  ATHLETE_DETAILS: "Athlete Details",
  COACH_DETAILS: "Coach Details",
  WORKOUT_LIBRARY: "Workout Library",
  LIBRARY_WORKOUT_DETAILS: "Library Workout",
  EDIT_LIBRARY_WORKOUT: "Edit Library Workout",
  EDIT_EVENT: "Edit Event",
  EVENT_DETAILS: "Event",
  ADMIN_TOOLS: "Admin Tools",
};
