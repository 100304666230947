import React, { useState } from "react";
import {
  Menu,
  Portal,
  Dialog,
  Paragraph,
  Button,
  Appbar,
} from "react-native-paper";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useCalendar } from "../../provider/CalendarProvider";
import { CalendarActionTypes } from "../../provider/CalendarProvider/types";
import { ICONS } from "../../constants/icons";
import { supabase } from "../../initSupabase";
import { SCREEN_NAMES } from "../../constants/screenNames";
import { deleteEvent } from "../../api/events";

const EventAppBarMenu = () => {
  const { state, dispatch } = useCalendar();
  const [menuVisible, setMenuVisible] = useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const navigation = useNavigation();
  const route = useRoute();

  const openMenu = () => setMenuVisible(true);
  const closeMenu = () => setMenuVisible(false);

  const handleEdit = () => {
    closeMenu();
    navigation.navigate(SCREEN_NAMES.EDIT_EVENT, {
      event: route.params.event,
    });
  };

  const handleDuplicate = () => {
    closeMenu();
    const eventToDuplicate = route.params.event;
    const newEvent = { ...eventToDuplicate };
    newEvent.id = null; // Set id to null to create a new activity
    navigation.navigate(SCREEN_NAMES.EDIT_EVENT, {
      event: newEvent,
      isDuplicateMode: true,
    });
  };

  const handleDelete = () => {
    closeMenu();
    setDeleteConfirmationVisible(true); // Show delete confirmation dialog
  };

  const confirmDelete = async () => {
    try {
      await deleteEvent(route.params.event.id);
      dispatch({ type: CalendarActionTypes.FETCH_ACTIVITIES });
      navigation.goBack();
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  const cancelDelete = () => {
    setDeleteConfirmationVisible(false); // Hide delete confirmation dialog
  };

  return (
    <>
      <Menu
        visible={menuVisible}
        onDismiss={closeMenu}
        anchor={<Appbar.Action icon="dots-vertical" onPress={openMenu} />}
      >
        <Menu.Item onPress={handleEdit} title="Edit" leadingIcon={ICONS.EDIT} />
        <Menu.Item
          onPress={handleDuplicate}
          title="Duplicate"
          leadingIcon={ICONS.DUPLICATE}
        />
        <Menu.Item
          onPress={handleDelete}
          title="Delete"
          leadingIcon={ICONS.DELETE}
        />
      </Menu>
      <Portal>
        <Dialog visible={deleteConfirmationVisible} onDismiss={cancelDelete}>
          <Dialog.Title>Confirmation</Dialog.Title>
          <Dialog.Content>
            <Paragraph>Are you sure you want to delete this event?</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={cancelDelete}>Cancel</Button>
            <Button onPress={confirmDelete}>Delete</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </>
  );
};

export default EventAppBarMenu;
