import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { Avatar, Button, Text, List, Divider } from "react-native-paper";
import { getUserInitials, getSexLabel } from "../utils/profileService";

interface ContactProfileProps {
  profile: {
    avatar: string;
    first_name: string;
    last_name: string;
    bio: string;
    email: string;
    phone: string;
    account_type: string;
    location: string;
    sex: string;
    date_of_birth: string;
  };
  onSchedule: () => void;
  onMessage: () => void;
  onCall: () => void;
}

const ContactProfile = ({
  profile,
  onSchedule,
  onMessage,
  onCall,
}: ContactProfileProps) => {
  const handlePressTrainingZones = () => {
    // Handle the press
    console.log("Training Zones Pressed");
  };
  return (
    <View>
      <View style={styles.container}>
        <Avatar.Text
          size={100}
          label={getUserInitials(profile)}
          style={styles.avatar}
        />
        <Text variant="headlineMedium">{`${profile.first_name} ${profile.last_name}`}</Text>
        <Text variant="labelLarge">{profile.bio}</Text>
        <View style={styles.buttonContainer}>
          <Button
            icon="calendar-today"
            mode="text"
            onPress={onSchedule}
            style={styles.button}
            compact={true}
          >
            Schedule
          </Button>
          <Button
            icon="message"
            mode="text"
            onPress={onMessage}
            style={styles.button}
            compact={true}
          >
            Message
          </Button>
          <Button
            icon="email"
            mode="text"
            onPress={onCall}
            style={styles.button}
            compact={true}
          >
            Email
          </Button>
        </View>
      </View>
      <Divider />
      <List.Section>
        <List.Subheader>Contact Details</List.Subheader>
        <List.Item
          title={profile.email}
          left={(props) => <List.Icon {...props} icon="email" />}
        />
      </List.Section>
      <Divider />
      <List.Section>
        <List.Subheader>About</List.Subheader>
        <List.Item
          title={profile.account_type}
          description="Account Type"
          left={(props) => <List.Icon {...props} icon="account-settings" />}
        />
        <List.Item
          title={profile.location}
          description="Location"
          left={(props) => <List.Icon {...props} icon="map-marker" />}
        />
        <List.Item title={getSexLabel(profile.sex)} description="Sex" />
        <List.Item title={profile.date_of_birth} description="Date of Birth" />
        <List.Item
          title="Training Zones"
          right={(props) => <List.Icon {...props} icon="chevron-right" />}
          onPress={handlePressTrainingZones}
        />
      </List.Section>
    </View>
  );
};

export default ContactProfile;

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    padding: 8,
  },
  avatar: { marginBottom: 8 },
  name: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
  },
  bio: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 20,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    marginVertical: 16,
  },
  button: {},
});
