import React from "react";
import {
  Icon,
  Card,
  Avatar,
  MD3TypescaleKey,
  IconButton,
} from "react-native-paper";
import { types } from "../../constants/activityTypes";
import { getActivityStatusColor } from "../utils/activityStatusColor";
import { formatDate } from "../utils/time";
import { ICONS } from "../../constants/icons";

interface EventCardTitleProps {
  title: string | null;
  activityTypeEnum: number | null;
  isCompleted: boolean | null;
  eventDate: Date | string | null;
  titleVariant?: keyof typeof MD3TypescaleKey;
}

// title={event.title}
// EventTypeEnum={event.type}
// isCompleted={event.is_completed}
// eventDate={event.event_date}

const EventCardTitle: React.FC<EventCardTitleProps> = ({
  title,
  activityTypeEnum,
  isCompleted = null,
  eventDate = null,
  titleVariant = "bodyLarge",
}) => {
  const getTypeLabel = () => {
    const activityType = types.find((t) => t.value === activityTypeEnum);
    if (activityType) {
      return activityType.label;
    }
    return "Unknown";
  };

  const getAvatarColor = () => {
    return getActivityStatusColor(isCompleted, eventDate);
  };

  return (
    <Card.Title
      title={title}
      titleVariant={titleVariant}
      subtitle={
        isCompleted !== null ? getTypeLabel() : formatDate(eventDate, true)
      }
      left={(props) =>
        isCompleted !== null ? (
          <Avatar.Icon
            {...props}
            icon={ICONS.EVENT}
            color="white"
            style={{
              backgroundColor: getAvatarColor(),
            }}
          />
        ) : (
          <Icon {...props} source={ICONS.EVENT} />
        )
      }
    />
  );
};

export default EventCardTitle;
