// api/events.ts
import { supabase } from "../initSupabase";
import {
  isSameWeek,
  startOfWeek,
  endOfWeek,
  isMonday,
  subDays,
  addDays,
} from "date-fns";
import { getPagination } from "./utils";
import { EventTypeValue, EventData } from "../types/event";

interface QueryOptions {
  user_id?: string;
  page?: number;
  before?: Date;
  after?: Date;
  pageSize?: number;
}

export async function fetchEvents({
  query: { user_id, page = 0, before, after, pageSize = 20 } = {},
}: { query?: QueryOptions } = {}) {
  try {
    // Extract the page from the query or default to 1
    // use pagination util to calculate the from and to values
    const { from, to } = getPagination(page, pageSize);
    //
    let supabaseQuery = supabase.from("events").select("*", { count: "exact" });
    // Create a filter object based on the provided before and after values
    if (before) {
      supabaseQuery = supabaseQuery.lt("event_date", before.toISOString());
    }
    if (after) {
      supabaseQuery = supabaseQuery.gte("event_date", after.toISOString());
    }
    if (user_id) {
      supabaseQuery = supabaseQuery.eq("user_id", user_id);
    }
    supabaseQuery = supabaseQuery
      .range(from, to)
      .order("event_date", { ascending: false });
    // Fetch event and return the raw data
    const { data, count, error } = await supabaseQuery;

    if (error) {
      throw error;
    }

    return {
      data: data,
      count: count,
      page: +page,
    }; // Return the raw data
  } catch (error) {
    console.error("Error fetching events:", error);
    throw error;
  }
}

export async function fetchNextEvent(user_id?: string): Promise<any | null> {
  try {
    const currentDate = new Date();
    const today = new Date(currentDate.setHours(0, 0, 0, 0));

    console.log("fetchNextEvent", today, user_id);

    // Fetch the first activity from today onwards where is_completed is false
    let supabaseQuery = supabase
      .from("events")
      .select("*")
      .eq("is_completed", false)
      .gte("event_date", today.toISOString())
      .order("event_date", { ascending: true })
      .limit(1);

    if (user_id) {
      supabaseQuery = supabaseQuery.eq("user_id", user_id);
    }

    const { data, error } = await supabaseQuery;

    if (error) {
      throw error;
    }

    // Return the first event or null if no matching event is found
    console.log("fetchNextEvent data", data);

    return data.length > 0 ? { data: data[0], error } : { data: null, error };
  } catch (error) {
    console.error("Error fetching next event:", error);
    throw error;
  }
}

// Fetch event by id
export async function fetchEventById(id: string) {
  try {
    const { data, error } = await supabase
      .from("events")
      .select("*")
      .eq("id", id)
      .single();

    if (error) {
      throw error;
    }

    // Return the latest completed activity or null if none found
    return data;
  } catch (error) {
    console.error("Error fetching event by id:", error);
    throw error;
  }
}

// Insert new event
export async function createEvent(event: EventData) {
  try {
    const { data, error } = await supabase
      .from("events")
      .insert([event])
      .single();

    if (error) {
      throw error;
    }

    return { data, error };
  } catch (error) {
    console.error("Error creating events:", error);
    throw error;
  }
}

// Update event
export async function updateEvent(event: EventData) {
  try {
    // set event.updated_at: new Date().toISOString() before updating
    event.updated_at = new Date().toISOString();

    const { data, error } = await supabase
      .from("events")
      .update(event)
      .eq("id", event.id)
      .single();

    if (error) {
      throw error;
    }

    return { data, error };
  } catch (error) {
    console.error("Error updating event:", error);
    throw error;
  }
}

// Delete event
export async function deleteEvent(id: string) {
  try {
    const { data, error } = await supabase
      .from("events")
      .delete()
      .eq("id", id)
      .single();

    if (error) {
      throw error;
    }

    return { data, error };
  } catch (error) {
    console.error("Error deleting event:", error);
    throw error;
  }
}
