import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
// import View from "../components/ThemedView";
import { Text, Divider } from "react-native-paper";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { types } from "../constants/activityTypes";
import { formatDuration, formatDate } from "../components/utils/time";
import ScreenWrapper from "../components/ScreenWrapper";
import EventCardTitle from "../components/Event/EventCardTitle";
import { getActivityStatusColor } from "../components/utils/activityStatusColor";
import {
  calculateFormatPace,
  calculateFormatUserSpeed,
} from "../components/utils/calculatePace";
import { useCalendar } from "../provider/CalendarProvider";
import { fetchEventById } from "../api/events";
import {
  formatDistance,
  metersToUserUnits,
  metersToFormattedUserUnits,
} from "../components/utils/distance";
import { speedOrPaceLabel } from "../components/utils/calculatePace";
import { EventData } from "../types/event";
import EventDetailsList from "../components/Event/EventDetailsList";

type EventDetailsProps = NativeStackScreenProps<
  MainStackParamList,
  SCREEN_NAMES.EVENT_DETAILS
>;

const EventDetails: React.FC<EventDetailsProps> = ({ route, navigation }) => {
  const { event } = route.params as { event: EventData };
  const [updatedEvent, setUpdatedEvent] = useState<EventData>(event);

  const { state, dispatch } = useCalendar();

  const eventStatusColor = getActivityStatusColor(
    updatedEvent.is_completed,
    updatedEvent.started_at
  );

  const formattedDuration = formatDuration(updatedEvent.total_time_planned);
  const formattedEventDate = formatDate(
    updatedEvent.event_date,
    updatedEvent.is_completed
  );

  // Fetch activity when the component mounts
  async function fetchUpdatedEvent(id: string) {
    try {
      const fetchedEvent = await fetchEventById(id);
      setUpdatedEvent(fetchedEvent);
      return fetchedEvent;
    } catch (error) {
      // Handle errors here
      console.error("Error fetching event:", error);
    } finally {
    }
  }

  useEffect(() => {
    if (state.fetchRequired) {
      fetchUpdatedEvent(updatedEvent.id);
    }
  }, [state.fetchRequired]);

  // have added fetch to get event recordings here which currently needs to be grabbed from server every time
  useEffect(() => {
    fetchUpdatedEvent(updatedEvent.id);
  }, []);

  return (
    <ScreenWrapper>
      <View style={styles.container}>
        <EventCardTitle
          title={updatedEvent.title}
          activityTypeEnum={updatedEvent.type}
          isCompleted={updatedEvent.is_completed}
          eventDate={updatedEvent.event_date}
          titleVariant="titleLarge"
        />
        <Text
          style={[
            styles.startedAt,
            { color: eventStatusColor }, // Conditionally set text color
          ]}
        >
          {formattedEventDate}
        </Text>
        {updatedEvent.description && (
          <View>
            <Text variant="labelMedium" style={styles.descriptionTitle}>
              Description
            </Text>
            <Text style={styles.description}>{updatedEvent.description}</Text>
          </View>
        )}
      </View>
      <Divider style={styles.divider} />
      <EventDetailsList event={updatedEvent} />
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  divider: {
    marginTop: 16,
  },
  container: {
    // flex: 1,
    padding: 16,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 8,
  },
  description: {
    fontSize: 16,
    marginBottom: 8,
  },
  descriptionTitle: {
    // fontSize: 16,
    marginBottom: 8,
  },
  type: {
    fontSize: 16,
    marginBottom: 8,
  },
  time: {
    fontSize: 16,
    marginBottom: 8,
  },
  startedAt: {
    fontSize: 16,
    marginBottom: 8,
  },
  completed: {
    fontSize: 16,
    marginBottom: 8,
  },
  centeredCell: {
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    marginBottom: 8,
    marginTop: 16,
  },
});

export default EventDetails;
