import React, { createRef, useEffect, useState } from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import {
  NavigationContainerRef,
  useNavigation,
} from "@react-navigation/native";
import CustomAppBar, { CustomAppBarProps } from "./CustomAppBar";
import Settings from "../screens/Settings";
import AccountSettings from "../screens/AccountSettings";
import MainTabs from "./MainTabs";
import About from "../screens/About";
import EditActivity from "../screens/EditActivity";
import ActivityDetails from "../screens/ActivityDetails";
import WorkoutLibrary from "../screens/WorkoutLibrary";
import ResetPassword from "../screens/auth/ResetPassword";
import MyCoaches from "../screens/MyCoaches";
import MyCoachDetails from "../screens/MyCoachDetails";
import MyAthletes from "../screens/MyAthletes";
import MyAthleteDetails from "../screens/MyAthleteDetails";
import * as Linking from "expo-linking";
import { useAuth } from "../provider/AuthProvider";
import ConnectedApps from "../screens/ConnectedApps";
import ConnectStrava from "../screens/ConnectStrava";
import { sendStravaAuthCodeToSupabase } from "../api/strava";
import MergeWorkouts from "../screens/MergeWorkouts";
import EditLibraryWorkout from "../screens/EditLibraryWorkout";
import LibraryWorkoutDetails from "../screens/LibraryWorkoutDetails";
import EventDetails from "../screens/EventDetails";
import EditEvent from "../screens/EditEvent";
import { ROUTES } from "../types/navigation";
import AdminTools from "../screens/AdminTools";
import { SCREEN_NAMES } from "../constants/screenNames";

const MainStack = createNativeStackNavigator();

// GitLab pages used for RN web build doesnt handle routes, causing 404s. Have fixed by
// using a # in the callback url, eg to /#api/auth/callback/strava
// this function removes the # with so the url and query params can be parsed
const parseHashedUrlToRoute = (url: string) => {
  let parsedUrl = url;
  if (url.includes("#")) {
    parsedUrl = url.replace("#", "");
  }

  return parsedUrl;
};

const Main = () => {
  const navigation = useNavigation();
  const renderHeader = (props: CustomAppBarProps) => {
    const viewItemBar = props.route.name === "Activity";

    return <CustomAppBar {...props} />;
  };
  const url = Linking.useURL();
  const { doResetPassword } = useAuth();
  const [stravaCallbackProcessed, setStravaCallbackProcessed] = useState(false);

  useEffect(() => {
    if (doResetPassword) {
      navigation.navigate("ResetPassword");
    }
  }, []);

  const handleStravaCallback = (url: string) => {
    const { queryParams } = Linking.parse(url);
    // This URL is redirected from Strava OAuth authorization
    // Make an API request to the Supabase backend, providing the code for token exchange and any other necessary data
    if (queryParams?.code && queryParams?.scope) {
      // && !stravaCallbackProcessed)
      sendStravaAuthCodeToSupabase(queryParams.code, queryParams.scope)
        .then((result) => {
          // once authorised, remove the /api/auth/callback/strava url and query parameters
          setStravaCallbackProcessed(true); // Mark the Strava callback as processed
          const newUrl = Linking.createURL("/");
          Linking.openURL(newUrl);
          alert("Success! Strava connected.");
        })
        .catch((error) => {
          // once authorisation attempt fails, remove the /api/auth/callback/strava url and query parameters
          setStravaCallbackProcessed(true); // Mark the Strava callback as processed
          const newUrl = Linking.createURL("/");
          Linking.openURL(newUrl);
          alert("Error during Strava authorization. Please try again.");
          console.error("Error during Strava authorization:", error);
        });
    } else {
      // once authorisation attempt fails, remove the /api/auth/callback/strava url and query parameters
      setStravaCallbackProcessed(true); // Mark the Strava callback as processed
      const newUrl = Linking.createURL("/");
      Linking.openURL(newUrl);
    }
  };

  const handleURL = (url: string) => {
    const parsedUrl = parseHashedUrlToRoute(url);
    // handle redirect from Strava OAuth here
    if (parsedUrl.includes("api/auth/callback/strava")) {
      handleStravaCallback(parsedUrl);
    }
  };

  useEffect(() => {
    // Do something with URL
    if (url) {
      handleURL(url);
    }
  }, [url]);

  return (
    <MainStack.Navigator
      initialRouteName="Chiron"
      screenOptions={({ route }) => ({
        // ts-ignore
        header: renderHeader,
        headerShown: route.name !== "ResetPassword", // Show header for all screens except "ResetPassword"
      })}
    >
      <MainStack.Screen name={SCREEN_NAMES.CHIRON} component={MainTabs} />
      <MainStack.Screen name={SCREEN_NAMES.ABOUT} component={About} />
      <MainStack.Screen name={SCREEN_NAMES.SETTINGS} component={Settings} />
      <MainStack.Screen
        name={SCREEN_NAMES.ACCOUNT_SETTINGS}
        component={AccountSettings}
      />
      <MainStack.Screen
        name={SCREEN_NAMES.EDIT_ACTIVITY}
        component={EditActivity}
      />
      <MainStack.Screen
        name={SCREEN_NAMES.MERGE_WORKOUTS}
        component={MergeWorkouts}
      />
      <MainStack.Screen
        name={SCREEN_NAMES.ACTIVITY_DETAILS}
        component={ActivityDetails}
      />
      <MainStack.Screen
        name={SCREEN_NAMES.RESET_PASSWORD}
        component={ResetPassword}
      />
      <MainStack.Screen
        name={SCREEN_NAMES.CONNECTED_APPS}
        component={ConnectedApps}
      />
      <MainStack.Screen
        name={SCREEN_NAMES.CONNECT_STRAVA}
        component={ConnectStrava}
      />
      <MainStack.Screen name={SCREEN_NAMES.MY_COACHES} component={MyCoaches} />
      <MainStack.Screen
        name={SCREEN_NAMES.MY_ATHLETES}
        component={MyAthletes}
      />
      <MainStack.Screen
        name={SCREEN_NAMES.ATHLETE_DETAILS}
        component={MyAthleteDetails}
      />
      <MainStack.Screen
        name={SCREEN_NAMES.COACH_DETAILS}
        component={MyCoachDetails}
      />
      <MainStack.Screen
        name={SCREEN_NAMES.WORKOUT_LIBRARY}
        component={WorkoutLibrary}
      />
      <MainStack.Screen
        name={SCREEN_NAMES.LIBRARY_WORKOUT_DETAILS}
        component={LibraryWorkoutDetails}
      />
      <MainStack.Screen
        name={SCREEN_NAMES.EDIT_LIBRARY_WORKOUT}
        component={EditLibraryWorkout}
      />
      <MainStack.Screen name={SCREEN_NAMES.EDIT_EVENT} component={EditEvent} />
      <MainStack.Screen
        name={SCREEN_NAMES.EVENT_DETAILS}
        component={EventDetails}
      />
      <MainStack.Screen name={ROUTES.ADMIN_TOOLS} component={AdminTools} />
    </MainStack.Navigator>
  );
};

export default Main;
